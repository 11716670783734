const ChangeMediaFileSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 11.375H4.5C1.785 11.375 0.625 10.215 0.625 7.5V4.5C0.625 1.785 1.785 0.625 4.5 0.625H7.5C10.215 0.625 11.375 1.785 11.375 4.5V7.5C11.375 10.215 10.215 11.375 7.5 11.375ZM4.5 1.375C2.195 1.375 1.375 2.195 1.375 4.5V7.5C1.375 9.805 2.195 10.625 4.5 10.625H7.5C9.805 10.625 10.625 9.805 10.625 7.5V4.5C10.625 2.195 9.805 1.375 7.5 1.375H4.5Z"
        fill="black"
      />
      <path
        d="M4.5 5.375C3.74 5.375 3.125 4.76 3.125 4C3.125 3.24 3.74 2.625 4.5 2.625C5.26 2.625 5.875 3.24 5.875 4C5.875 4.76 5.26 5.375 4.5 5.375ZM4.5 3.375C4.155 3.375 3.875 3.655 3.875 4C3.875 4.345 4.155 4.625 4.5 4.625C4.845 4.625 5.125 4.345 5.125 4C5.125 3.655 4.845 3.375 4.5 3.375Z"
        fill="black"
      />
      <path
        d="M1.33489 9.85027C1.21489 9.85027 1.09489 9.79027 1.02489 9.68527C0.909886 9.51527 0.954886 9.28027 1.12989 9.16527L3.59489 7.51027C4.13489 7.14527 4.87989 7.19027 5.36989 7.60527L5.53489 7.75027C5.78489 7.96527 6.20989 7.96527 6.45489 7.75027L8.53489 5.96527C9.06489 5.51027 9.89989 5.51027 10.4349 5.96527L11.2499 6.66527C11.4049 6.80027 11.4249 7.03527 11.2899 7.19527C11.1549 7.35027 10.9199 7.37027 10.7599 7.23527L9.94489 6.53527C9.69489 6.32027 9.26989 6.32027 9.01989 6.53527L6.93989 8.32027C6.40989 8.77527 5.57489 8.77527 5.03989 8.32027L4.87489 8.17527C4.64489 7.98027 4.26489 7.96027 4.00989 8.13527L1.54489 9.79027C1.47989 9.83027 1.40489 9.85027 1.33489 9.85027Z"
        fill="black"
      />
    </svg>
  );
};

export { ChangeMediaFileSvg };
