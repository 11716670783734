import { ChangeEvent } from "react";
import styles from "./Textarea.module.scss";

const TextArea = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  return (
    <textarea
      className={styles.textarea}
      value={value}
      onChange={(e) => onChange(e)}
    />
  );
};

export { TextArea };
