const statistics = {
  getSatisticEvents: 'statistics/events/statistics',
  createEvent: 'statistics/events',
  getStatisticInfo: (id: string) => `statistics/events/${id}`,
  updateStatisticEvent: (id: string) => `statistics/events/${id}`,
  removeStatisticEvetn: (id: string) => `statistics/events/${id}`
}

const emailEndpoints = {
  getAllTemplates: 'template',
  getHystorys: 'email/history',
  removeHysrotys: 'email/history',
  getTemplates: 'template/keys',
  createTemplates: 'template',
  templateInfo: (id: string) => `template/${id}`,
  getTemplateKeys: 'template/keys',
  templateUpdate: (id: string) => `template/${id}`,
  getSmtp: 'smtp',
  editSmtp: 'smtp',
  removeTemplate: 'admin/setting',
}

const logsEndpoints = {
  getAllLogs: 'logs/frontend',
  removeFrontendLogs: 'logs/frontend',
  getLogs: 'logs',
  removeLogs: 'logs',
}

const systemEndpoints = {
  getBackendExport: 'system/backend/export',
  backendImport: 'system/backend/import',
}

const adminEndpoints = {
  getUsers: 'admin/users',
  changePassword: 'admin/current/password',
  createUser: 'admin/users',
  getUser: (id: string) => `admin/users/${id}`,
  getUserForm: (id: string) => `admin/user/${id}`,
  editUserAvatar: (id: string) => `admin/users/avatar/${id}`,
  editUserInfo: (id: string) => `admin/users/${id}`,
  editUserPassword: (id: string) => `admin/users/password/${id}`,
}


const feedbackEndpoints = {
  getInfo: (id: string) => `feedback/${id}`,
  edit: (id: string) => `feedback/${id}`,
  getAll: 'feedback',
  remove: (id: string) => `feedback/${id}`,
}

const configurationEndroints = {
  getKeyInfo: (id: string) => `configuration/keys/${id}`,
  keyEdit: (id: string) => `configuration/keys/${id}`,
  getAllKeys: 'configuration/keys/',
  removeKey: (id: string) => `configuration/keys/${id}`,
  createKey: 'configuration/keys',
}

const userEndpoints = {
  getCurrent: 'users/current',
  changeAvatar: 'users/current/avatar',
  changeInfo: 'users/current',
  getRoles: 'roles',
  editUser: 'users/current',
  editPassword: 'users/current/password',
  editUserAvatar: 'users/avatar',
  changePassword: 'users/current/password',
  deleteUser: (id: string) => `admin/users/${id}`
}

const authEndpoints = {
  login: 'login',
  register: 'requests',
  recoveryPassword: 'change/password',
  recoveryChangePassword: (key: string) => `change/password/recovery/${key}`,
}
//-------------Template-------------

const codeEndpoints = {
  getCodes: 'auth/code',
  remove: (id: string) => `auth/code/${id}`,
  createCode: 'auth/code/custom',
  generateCode: 'auth/code/count',
  getPreview: 'auth/code/load/preview',
  getPdf: 'auth/code/load/pdf',
  downloadPdf: '/auth/code/pdf'
}

const surveyEndpoints = {
  create: 'surveys',
  getAll: 'surveys',
  getInfo: (id: string) => `surveys/${id}`,
  remove: (id: string) => `surveys/${id}`,
}

const demoEndpoints = {
  create: 'demos',
  getAll: 'demos',
  getInfo: (id: string) => `demos/${id}`,
  remove: (id: string) => `demos/${id}`,
}

const orderEndpoints = {
  create: 'orders',
  getAll: 'orders',
  getInfo: (id: string) => `orders/${id}`,
  remove: (id: string) => `orders/${id}`,
}

const metricEndpoints = {
  getAll: 'yandex_metric',
  updateMetric: 'yandex_metric'

}

export const ENDPOINT_LIST = {
  adminEndpoints,
  userEndpoints,
  statistics,
  logsEndpoints,
  configurationEndroints,
  feedbackEndpoints,
  systemEndpoints,
  emailEndpoints,
  authEndpoints,
  metricEndpoints,
  codeEndpoints,
  surveyEndpoints,
  demoEndpoints,
  orderEndpoints,
}
