import { useAppSelector } from "../../../shared";
import { confirmSlice } from "./slice";

const confirmSelect = (state: RootState) => state.confirm;
export const confirmAction = confirmSlice.actions;
export const confirmReducer = confirmSlice.reducer;

export const useConfirmSelect = () => {
  return useAppSelector(confirmSelect);
};
