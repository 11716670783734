import {   createAppThunk } from "../../../shared";
import { CONFIG_APP } from "../../../shared/config";
import { CookieService } from "../../../shared/service";
import { AuthorizationInput } from "../DTO/auth-dto";
import { authAdapter } from "../service";


export const fetchAuthorization = createAppThunk(
  "auth/fetchAuthorization",
  async (payload: AuthorizationInput, { rejectWithValue }) => {
    const response = await authAdapter.authorization(payload);

   
    if (response.status === 'error' || !response.data) {
      return rejectWithValue(
        `${response.message}`
      );
    }

    if (response.data?.tokens) {
      CookieService.set(response.data.tokens.accessToken, CONFIG_APP.ACCESS_TOKEN_COOKIE, CONFIG_APP.COOKIE_TIME);
      CookieService.set(response.data.tokens.refreshToken, CONFIG_APP.REFRESH_TOKEN_COOKIE);
    }

    return response.data.user;
  }
);

