export const METODATA_TITLES: Record<string, string> = {
  '/dashboard': 'Актуальные обращения',
  '/users': 'Пользователи',
  '/users/add': 'Создать нового пользователя',
  '/feedbacks': 'Обратная связь',
  '/email-templates': 'Шаблоны электронной почты',
  '/email-templates/create': 'Создание шаблона электронной почты',
  '/email-history': 'История рассылки',
  '/settings': 'Общие настройки',
  '/configuration/keys': 'Конфигуратор ключей',
  '/configuration/keys/add': 'Создайте новый конфигурационный ключ',
  '/app-logs': 'Журналы приложений',
  '/app-statistics': 'Статистика заявок',
  '/events': 'События',
  '/events/add': 'Добавить событие',
  '/common-settings': 'Общие настройки',
  '/logs': 'Логи',
  '/update-system': 'Обновление системы',
  '/login': 'Авторизация',
  '/account': 'Профиль',
  //----template--------

  '/codes': 'Коды',
  '/metrics': "Метрика"
}

export const METODATA_TITLES_DINAMIC: Record<string, string> = {
  '/users/info/': 'Информация пользователя',
  '/users/edit/': 'Редактировать пользователя',
  '/feedbacks/edit/': 'Редактировать отзыв',
  '/email-templates/edit/': 'Обновление шаблона электронной почты',
  '/events/edit/': 'Редактировать событие',
  //----template--------  

  '/metrics/update': 'Изменить запись',
  '/metrics/info': 'Данные'
}
