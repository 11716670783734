import UsersSvg from "../../../public/assets/svg/users-svgrepo-com.svg";
import FeedbackSvg from "../../../public/assets/svg/feedback-svgrepo-com.svg";
import MailSvg from "../../../public/assets/svg/mail-svgrepo-com.svg";
import TemplateSvg from "../../../public/assets/svg/template-svgrepo-com.svg";
import HistorySvg from "../../../public/assets/svg/history-svgrepo-com.svg";
import SettingSvg from "../../../public/assets/svg/settings-2-svgrepo-com.svg";
import SettingOptionSvg from "../../../public/assets/svg/settings-menu-option.svg";
import KeySvg from "../../../public/assets/svg/key-svgrepo-com.svg";
import MonitorSvg from "../../../public/assets/svg/monitor-svgrepo-com.svg";
import ErrorSvg from "../../../public/assets/svg/error-16-svgrepo-com.svg";
import EventSvg from "../../../public/assets/svg/events-svgrepo-com.svg";
import SystemSvg from "../../../public/assets/svg/system-expired-line-svgrepo-com.svg";
import SystemSettingsSvg from "../../../public/assets/svg/settings-general.svg";
import SystemLogsSvg from "../../../public/assets/svg/code-tech-dev-svgrepo-com.svg";
import SystemUpdateSvg from "../../../public/assets/svg/update-svgrepo-com.svg";
import DashboardSvg from "../../../public/assets/svg/dashboard-svgrepo-com.svg";
import type { SvgIconProps } from "../types/svgIconComponsets";
//------------Template-----------------

import CodesSvg from '../../../public/assets/svg/codes.svg'
import DownloadSvg from '../../../public/assets/svg/download_icon_128877.svg'
import MetricSvg from '../../../public/assets/svg/metrics-svgrepo-com.svg'

export type MenuDesingModel = {
  href: string;
  icon: SvgIconProps;
  title: string;
};

type MenuSystemModel = {
  href?: string;
  icon: SvgIconProps;
  title: string;
  list?: MenuDesingModel[];
};

export const menuSystem: MenuSystemModel[] = [
  {
    href: "/dashboard",
    icon: DashboardSvg,
    title: "Дашборд",
  },
  {
    href: '/codes',
    icon: CodesSvg,
    title: 'Коды'
  },
  {
    href: "/users",
    icon: UsersSvg,
    title: "Пользователи",
  },
  {
    href: "/feedbacks",
    icon: FeedbackSvg,
    title: "Обратная связь",
  },
  {
    href: "/metrics",
    icon: MetricSvg,
    title: "Метрика"
  },
  {
    icon: MailSvg,
    title: "Почтовые шаблоны",
    list: [
      {
        href: "/email-templates",
        icon: TemplateSvg,
        title: "Шаблоны",
      },
      {
        href: "/email-history",
        icon: HistorySvg,
        title: "История",
      },
    ],
  },
  {
    icon: SettingSvg,
    title: "Настройки",
    list: [
      {
        href: "/settings",
        icon: SettingOptionSvg,
        title: "Настройки",
      },
      {
        href: "/configuration/keys",
        icon: KeySvg,
        title: "Ключевой конфигуратор",
      },
    ],
  },
  {
    icon: MonitorSvg,
    title: "Работа приложения",
    list: [
      {
        href: "/app-logs",
        icon: ErrorSvg,
        title: "Журналы ошибок",
      },
      {
        href: "/events",
        icon: EventSvg,
        title: "События",
      },
    ],
  },
  {
    icon: SystemSvg,
    title: "Система",
    list: [
      {
        href: "/common-settings",
        icon: SystemSettingsSvg,
        title: "Общие настройки",
      },
      {
        href: "/logs",
        icon: SystemLogsSvg,
        title: "Логи",
      },
      {
        href: "/update-system",
        icon: SystemUpdateSvg,
        title: "Обновление системы",
      },
    ],
  },
];
