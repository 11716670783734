import { useState } from "react";
import { Validator } from "../service/Validator";

const getEmptyErrorsObj = <T>(obj: T): T => {
  const result: Record<string, string> = {};
  for (let field in obj) {
    result[field] = '';
  }
  return result as T;
}

interface FormValuesProps<T> {
  required: (keyof T)[];
  initValues: T;
}

const useFormValues = <T extends object>({ initValues, required }: FormValuesProps<T>) => {
  const [values, setValues] = useState<T>(initValues);
  const [errors, setErrors] = useState<T>(() => getEmptyErrorsObj<T>(initValues));
  const [isChange, setIsChange] = useState<boolean>(false);

  const setErrorValue = (value: string, field: keyof T) => {
    setErrors(prev => ({ ...prev, [field]: value }))
  }

  const resetIsChange = () => {
    setIsChange(false);
  }


  const validateValues = () => {
    const errorsObj = Validator.validate<T>(values, required);

    if (errorsObj) {
      setErrors((prev) => ({ ...prev, ...errorsObj }));
      return true;
    } else {
      if (isChange) {
        setIsChange(prev => !prev);
      }
      return false;
    };
  };


  const resetErrorField = (field: keyof T) => {
    if (!errors) return;
    setErrorValue('', field);
  };

  const resetInput = () => {
    setValues(() => initValues);
  };


  const changeInputs = (value: string, field: keyof T) => {
    if (errors && errors.hasOwnProperty(field)) {
      resetErrorField(field);
    }

    if (!isChange) {
      setIsChange(prev => !prev);
    }

    setValues((prev) => ({ ...prev, [field]: value }));
  };

  const setInitValues = (object: T) => {
    setValues(() => ({ ...object }));
  };


  return { resetIsChange, values, errors, changeInputs, validateValues, resetInput, setErrorValue, isChange, setInitValues }
}

export { useFormValues }
