import { useCallback } from "react";
import { useAppDispatch } from "../../../shared";
import { useLayoutSelect, layoutAction} from "../model";


export function useToggleSideMenu() {
  const dispatch = useAppDispatch();

   const toggleSideMenu = useCallback(({ value }: {value: boolean | null}) => {
    dispatch(layoutAction.toggleMenuIsOpen({ value }))
  },[dispatch]);

  return { toggleSideMenu };
}

export function useSideIsOpen() {
  const { sideBarSubMenuOpen } = useLayoutSelect();

  return { isOpen: sideBarSubMenuOpen };
}


