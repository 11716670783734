import * as authApi from "../api/auth-api";
import { AuthService } from "../application/port";


export const authAdapter: AuthService = {
   authorization(payload) {
       return authApi.authorization(payload);
   },
   registrationUser(payload) {
    return authApi.registrationUser(payload);
   },
   fethCurrentInfo() {
       return authApi.fethCurrentInfo();
   },
   changeAvatar(payload) {
       return authApi.fethChangeAvatar(payload);
   },
   changeMainInfo(payload) {
       return authApi.fethChangeUserMainInfo(payload)
   },
   changePassword(payload) {
       return authApi.fethChangeUserPassword(payload)
   },
   fethGetAllUsers(params) {
       return authApi.fethGetAllUsers(params)
   },
   recoveryPassword(payload) {
    return authApi.fethRecoveryPassword(payload)
   },
   recoveryChangePassword(payload) {
       return authApi.fethRecoveryChangePassword(payload)
   },
};
