import {
  Box
} from '@mui/material';

const BeforeLoading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100vw',
        height: '150%'
      }}
    >
      <Box
        sx={{
          padding: 1,
          backgroundColor: '#5664d2',
          height: 50,
          width: '100%'
        }}
      ></Box>

      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <Box
          sx={{
            padding: 1,
            backgroundColor: 'white',
            width: 256,
            height: '100%'
          }}
        ></Box>
        <Box
          sx={{
            padding: 1,
            backgroundColor: '#f4f6f8',
            height: '100%',
            width: '100%'
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export { BeforeLoading };
