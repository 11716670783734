import { useLayoutEffect, useMemo, useRef } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { getTitleHeader } from "@/shared/service/get-title-header";
import { CONFIG_APP } from "@/shared/config";
import { CookieService } from "@/shared";

import styles from "./ContentHeaderTitle.module.scss";

type Props = {
  isLoginPage: boolean;
  pathname: string;
}

const ContentHeaderTitle = ({ isLoginPage, pathname }: Props) => {
  const titleRef = useRef<string | null>(null);
  const navigate = useNavigate();

 useLayoutEffect(() => {
   const title = getTitleHeader(pathname);
   document.title = title || 'Главная';

   titleRef.current = title || 'Главная';

   if (
     pathname !== '/login' &&
     CookieService.get(CONFIG_APP.ACCESS_TOKEN_COOKIE) === ''
   ) {
     navigate('/login', { replace: true });
   }
 }, [pathname]);

 const titleValue = useMemo(() => getTitleHeader(pathname) || 'Главная', [ pathname ]);

 if (isLoginPage) {
  return null;
 }

  return (
    <h2 className={styles.contentHeaderTitle}>
     {titleValue}
      </h2>
  );
};

export { ContentHeaderTitle };