import {
    Box,
    Container,
    Typography
} from '@mui/material';
import notFoundPng from '../../../public/assets/images/not_found.png'; 


const NotFoundPage = () => (
    <>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
        >
            <Container maxWidth="md">
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="h1"
                >
                    404: Страницы, которую вы ищете, здесь нет
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="subtitle2"
                >
                  Вы либо выбрали какой-то сомнительный маршрут, либо попали сюда по ошибке.
Что бы это ни было, попробуйте воспользоваться навигацией
                </Typography>
                <Box sx={{textAlign: 'center'}}>
                    <img 
                    src={notFoundPng} 
                    alt='Page not found'
                    style={{
                        marginTop: 50,
                        display: 'inline-block',
                        maxWidth: '100%',
                        width: 560
                    }}
                    />
                </Box>
            </Container>
        </Box>
    </>
);

export { NotFoundPage };
