import { CONFIG_APP } from '../config'
import { CookieMethods } from './cookies-service';

export const CookieService = {
  name: CONFIG_APP.ACCESS_TOKEN_COOKIE,
  set: function (value: string, name?: string, time?: number) {
    const timer = time ? { "max-age": time } : false;
    const cookieName = name ? name : this.name;
    CookieMethods.set(cookieName, value, timer);
  },
  get: function (name?: string) {
    const cookieName = name ? name : this.name;
    return CookieMethods.get(cookieName);
  },
  remove: function (name?: string) {
    const cookieName = name ? name : this.name;
    CookieMethods.delete(cookieName);
  },
};

