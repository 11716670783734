import { lazy } from "react";

export const AccountPage = lazy(() => import("./Account/Account"));
export const DashboardPage = lazy(() => import("./Dashboard/Dashboard"));
export const UserInfoPage = lazy(() => import("./User/user-info/UserInfo"));
export const UserEditPage = lazy(() => import("./User/user-edit/UserEdit"));
export const UserAddPage = lazy(() => import("./User/user-add/UserAdd"));
export const UserListPage = lazy(() => import("./User/UserList"));
export const FeedBackEditPage = lazy(() => import("./FeedBacks/FeedBackEdit"));
export const FeedBackListPage = lazy(() => import("./FeedBacks/FeedBackList"));
export const EmailTemplateListPage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateList")
);
export const EmailHistoryListPage = lazy(
  () => import("./Templates/Email-Hstory/EmailHistoryList")
);
export const EmailTemplateCreatePage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateCreate")
);
export const EmailTemplateEditPage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateEdit")
);
export const AppLogsPage = lazy(() => import("./AppWork/AppLogs"));
export const AppStatisticsPage = lazy(
  () => import("./AppStatistics/AppStatistics")
);
export const AppStatisticsEventsListPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsList")
);
export const AppStatisticsEventsAddPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsAdd")
);
export const AppStatisticsEventsEditPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsEdit")
);
export const LogsPage = lazy(() => import("./Systems/Logs/Logs"));
export const CommonSettingsPage = lazy(
  () => import("./Systems/CommonSettings/CommonSettings")
);
export const UpdateSystemPage = lazy(
  () => import("./Systems/UpdateSystem/UpdateSystem")
);
export const SettingsExamplPage = lazy(
  () => import("./Settings/SettingsExampl")
);
export const ConfigurationKeysListPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysList")
);
export const ConfigurationKeysAddPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysAdd")
);
export const ConfigurationKeysEditPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysEdit")
);
export const LoginPage = lazy(() => import("./Login/Login"));
//------Template---------

export const CodeListPage = lazy(() => import("./Codes/CodesList"))
// export const PrintCodesPage = lazy(() => import('./Codes/codes-print/CodesPrint'))

export const MetricsListPage = lazy(() => import('./Metrics/MetricsList'))
export const MetricsUpdatePage = lazy(() => import('./Metrics/update-metric/UpdateMetric'))
export const MetricInfoPage = lazy(() => import('./Metrics/metric-info/MetricInfo'))


export const TaskAddPage = lazy(() => import("./Task/TaskAdd"));
export const TaskEditPage = lazy(() => import("./Task/TaskEdit"));
