import { AuthStorageService } from "../domen/service";
import { AuthorizationInput } from '../DTO/auth-dto';
import { authAction, useAuthSelect } from "../model";
import { fetchAuthorization } from "../model/thunk";
import {  useAppDispatch } from "../../../shared";
import { CookieService } from "../../../shared/service";
import { useNavigate } from "react-router-dom";
import { CONFIG_APP } from "../../../shared/config";
import { useActiveNotification } from "@/entities/notification";


const authStorageService = new AuthStorageService()


export function useFetchAuthorization() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeNotification } = useActiveNotification();

  const handleAuthorization = async(payload: AuthorizationInput, callback?: () => void) => {

    const validationFields = authStorageService.loginAuth(payload)

    if (!validationFields) return;

   const authResponse = await dispatch(fetchAuthorization(validationFields));


   if (authResponse.meta.requestStatus === 'fulfilled') {
     const loginUserName = typeof authResponse?.payload !== 'string' &&  authResponse?.payload?.username;
     const role = typeof authResponse?.payload !== 'string' && authResponse?.payload?.role;

     if (role !== 'admin') {
       return activeNotification({ status: 'error', message: 'Доступно запрещен' })
    }

     if (loginUserName) {
       navigate(CONFIG_APP.START_PAGE_AFTER_LOGIN);
    }
    if (callback) {
      callback();
    }
    return null;
   } else {
    if (authResponse.payload) {
      let rusError = authResponse.payload;
      
      if (authResponse.payload === 'Password or username are wrong') {
        rusError = 'Неверный логин или пароль'
      } else if (String(authResponse.payload).includes('User with username')) {
        rusError = `Пользователь с логином ${validationFields.username} не найден`
      } else if (String(authResponse.payload).includes('Password length must be at least 8 characters long')) {
        rusError = 'Длина пароля должна составлять не менее 8 символов'
      }
   
      return rusError;
    }
   }

  }

  return { handleAuthorization };
}

export function useLogoutUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    dispatch(authAction.logoutAuth())
    CookieService.remove(CONFIG_APP.ACCESS_TOKEN_COOKIE);
    CookieService.remove(CONFIG_APP.REFRESH_TOKEN_COOKIE);
    navigate('/');
    
  }

  return { logoutUser };
}

export function useAuth() {
  const { authorization } = useAuthSelect();

  return { authorization };
}


export function useChangeUserInfo() {
  const dispatch = useAppDispatch();

  const changeUserInfo = (payload: { name: string, email: string, phone: string, fio: string, avatar: string }) => {
    dispatch(authAction.changeUserInfo(payload))

  }

  return { changeUserInfo };
}

export function useChangeUserAvatar() {
  const dispatch = useAppDispatch();

  const changeAvatar = (payload: { avatar: string }) => {
    dispatch(authAction.changeUserAvatar(payload))

  }

  return { changeAvatar };
}

export function useToggleRemember() {
  const dispatch = useAppDispatch();

  const toggleRemember = () => {
    dispatch(authAction.rememberToggle())

  }

  return { toggleRemember };
}


export function useIsLogoutModalOpen() {
  const dispatch = useAppDispatch();

  const toggleLogoutModal = () => {
    dispatch(authAction.setToogleLogoutModal())
  }

  return { toggleLogoutModal };
}



export function useRegistrationResponse() {
  const dispatch = useAppDispatch();

  const changeRegistrationResponse = ({ value }: { value: 'success' | 'error' | null }) => {
    dispatch(authAction.setRegistrationResponse({ value }))
  }

  return { changeRegistrationResponse };
}


