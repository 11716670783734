import { AuthInitState } from "./types";

const initialState: AuthInitState = {
  user: null,
  authorization: false,
  loading: false,
  error: null,
  isRemember: false,
  isModalOpen: false,
  isLogoutModalOpen: false,
  registrationResponse: null,
};

export { initialState };
