import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import * as page from "../../pages";
import { NAV } from "./nav-links";
import { NotFoundPage } from "../../pages/NotFound/NotFound";
import { MainLayout } from "@/widgets/layouts/main-layout/MainLayout";

export const routerApp = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={`${NAV.MAIN}`} element={<MainLayout />}>
        <Route index element={<page.LoginPage />} />

        <Route path={`${NAV.ACCOUNT}`} element={<page.AccountPage />} />
        <Route path={`${NAV.DASHBOARD}`} element={<page.DashboardPage />} />
        <Route path={`${NAV.CODES_LIST}`} element={<page.CodeListPage />} />
        <Route path={`${NAV.USER_INFO}`} element={<page.UserInfoPage />} />
        <Route path={`${NAV.USER_EDIT}`} element={<page.UserEditPage />} />
        <Route path={`${NAV.USER_ADD}`} element={<page.UserAddPage />} />
        <Route path={`${NAV.USER_LIST}`} element={<page.UserListPage />} />

        <Route
          path={`${NAV.FEEDBACK_LIST}`}
          element={<page.FeedBackListPage />}
        />
        <Route
          path={`${NAV.FEEDBACK_EDIT}`}
          element={<page.FeedBackEditPage />}
        />
        <Route
          path={`${NAV.METRICS_LIST}`}
          element={<page.MetricsListPage />}
        />
        <Route
          path={`${NAV.METRICS_EDIT}`}
          element={<page.MetricsUpdatePage />}
        />
        <Route
          path={`${NAV.EMAIL_TEMPLATES}`}
          element={<page.EmailTemplateListPage />}
        />
        <Route
          path={`${NAV.EMAIL_HISTORY}`}
          element={<page.EmailHistoryListPage />}
        />
        <Route
          path={`${NAV.EMAIL_CREATE}`}
          element={<page.EmailTemplateCreatePage />}
        />
        <Route
          path={`${NAV.EMAIL_EDIT}`}
          element={<page.EmailTemplateEditPage />}
        />

        <Route path={`${NAV.LOGS_LIST}`} element={<page.AppLogsPage />} />

        {/* <Route path={`${NAV.APP_STATISTICS}`}  element={<page.AppStatisticsPage />} /> */}
        <Route
          path={`${NAV.APP_STATISTICS_EVENTS}`}
          element={<page.AppStatisticsEventsListPage />}
        />
        <Route
          path={`${NAV.APP_STATISTICS_ADD}`}
          element={<page.AppStatisticsEventsAddPage />}
        />
        <Route
          path={`${NAV.APP_STATISTICS_EDIT}`}
          element={<page.AppStatisticsEventsEditPage />}
        />

        <Route path={`${NAV.LOGS}`} element={<page.LogsPage />} />
        <Route
          path={`${NAV.COMMON_SETTINGS}`}
          element={<page.CommonSettingsPage />}
        />
        <Route
          path={`${NAV.UPDATE_SYSTEM}`}
          element={<page.UpdateSystemPage />}
        />

        <Route path={`${NAV.SETTINGS}`} element={<page.SettingsExamplPage />} />

        <Route
          path={`${NAV.CONFIGURATION_KEYS}`}
          element={<page.ConfigurationKeysListPage />}
        />
        <Route
          path={`${NAV.CONFIGURATION_KEYS_ADD}`}
          element={<page.ConfigurationKeysAddPage />}
        />
        <Route
          path={`${NAV.CONFIGURATION_KEYS_EDIT}`}
          element={<page.ConfigurationKeysEditPage />}
        />

        <Route path={`${NAV.NO_PAGE}`} element={<NotFoundPage />} />
        {/* ----------------------Template------------------------ */}

        <Route path={`${NAV.TASK_ADD}`} element={<page.TaskAddPage />} />
        <Route path={`${NAV.TASK_EDIT}`} element={<page.TaskEditPage />} />
      </Route>
    </>
  )
);
