export const NAV = {
  ACCOUNT: 'account',
  DASHBOARD: 'dashboard',
  USER_INFO: 'users/info/:id',
  USER_EDIT: 'users/edit/:id',
  USER_ADD: 'users/add',
  USER_LIST: 'users',
  FEEDBACK_EDIT: 'feedbacks/edit/:id',
  FEEDBACK_LIST: 'feedbacks',
  EMAIL_TEMPLATES: 'email-templates',
  EMAIL_HISTORY: 'email-history',
  EMAIL_CREATE: 'email-templates/create',
  EMAIL_EDIT: 'email-templates/edit/:id',
  LOGS_LIST: 'app-logs',
  // APP_STATISTICS: 'app-statistics',
  APP_STATISTICS_EVENTS: 'events',
  APP_STATISTICS_ADD: 'events/add',
  APP_STATISTICS_EDIT: 'events/edit/:id',
  LOGS: 'logs',
  COMMON_SETTINGS: 'common-settings',
  UPDATE_SYSTEM: 'update-system',
  SETTINGS: 'settings',
  CONFIGURATION_KEYS: 'configuration/keys',
  CONFIGURATION_KEYS_ADD: 'configuration/keys/add',
  CONFIGURATION_KEYS_EDIT: 'configuration/keys/edit/:id',
  LOGIN: 'login',
  MAIN: "/",
  NO_PAGE: "*",
  //------Template---------

  CODES_LIST: 'codes',
  METRICS_LIST: 'metrics',
  METRICS_EDIT: 'metrics/update/:id',
  METRICS_INFO: 'metrics/info/:id',
  // PRINT_CODES: 'codes/print',

  TASK_ADD: 'tasks/add/:id',
  TASK_EDIT: 'tasks/edit/:id',

};
