const AddPhotoCircleSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.375C3.035 11.375 0.625 8.965 0.625 6C0.625 3.035 3.035 0.625 6 0.625C8.965 0.625 11.375 3.035 11.375 6C11.375 8.965 8.965 11.375 6 11.375ZM6 1.375C3.45 1.375 1.375 3.45 1.375 6C1.375 8.55 3.45 10.625 6 10.625C8.55 10.625 10.625 8.55 10.625 6C10.625 3.45 8.55 1.375 6 1.375Z"
        fill="black"
      />
      <path
        d="M8 6.375H4C3.795 6.375 3.625 6.205 3.625 6C3.625 5.795 3.795 5.625 4 5.625H8C8.205 5.625 8.375 5.795 8.375 6C8.375 6.205 8.205 6.375 8 6.375Z"
        fill="black"
      />
      <path
        d="M6 8.375C5.795 8.375 5.625 8.205 5.625 8V4C5.625 3.795 5.795 3.625 6 3.625C6.205 3.625 6.375 3.795 6.375 4V8C6.375 8.205 6.205 8.375 6 8.375Z"
        fill="black"
      />
    </svg>
  );
};

export { AddPhotoCircleSvg };
