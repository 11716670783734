import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useLogoutUser, useSideIsOpen, useToggleSideMenu } from "@/entities";

import MenuSvg from "../../../../public/assets/svg/menu-svgrepo-com.svg";
import InputSvg from "../../../../public/assets/svg/input-svgrepo-com.svg";
import LogoSvg from "../../../../public/assets/svg/logo.svg";

import styles from "./LayoutHeader.module.scss";

type Props = {
  isLoginPage: boolean;
};

const LayoutHeader = ({ isLoginPage }: Props) => {
  const { logoutUser } = useLogoutUser();
  const { toggleSideMenu } = useToggleSideMenu();
  const { isOpen } = useSideIsOpen();

  return (
    <header className={styles.header}>
      <Link to="/">
        <LogoSvg width={52} height={52} className={styles.headerLogo} />
      </Link>
      <div>
        {!isLoginPage && (
          <>
            <IconButton color="inherit" size="large" onClick={logoutUser}>
              <InputSvg width={26} height={26} color="white" />
            </IconButton>
            <IconButton
              className={styles.buttonMenu}
              color="inherit"
              onClick={() => toggleSideMenu({ value: !isOpen })}
              size="large"
            >
              <MenuSvg width={20} height={20} color="white" />
            </IconButton>
          </>
        )}
      </div>
    </header>
  );
};

export { LayoutHeader };
