import { AuthorizationInput } from "../DTO/auth-dto";


export class AuthStorageService {
  constructor() {}

  public loginAuth({ username, password }: AuthorizationInput): AuthorizationInput | null {

    const validLogin = username.length > 1  
    const validPass = password.length > 1

    if (!validLogin || !validPass) return null;

    return {
      username: username,
      password: password
    }
  }



}